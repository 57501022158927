// DOM-specific JS should be done here
// see /boilerplate/src/js/browser.js for the import pattern

import select from 'dom-select';

export const WorkStudy = (el) => {
  const ui = {
    el,
    hrs: select.all('.hr', el),
    images: select.all('.image', el),
  };

  // console.log(ui);
  console.log('work study fired');

  const handleClientColor = () => {
    const dataColor = ui.el.getAttribute('data-client-color');

    ui.hrs.forEach((hr) => {
      hr.style.backgroundColor = dataColor;
    });

    ui.images.forEach((image) => {
      image.classList.add('client-branded');
      image.style.borderColor = dataColor;
    });
  };

  const init = () => {
    handleClientColor();
  };

  init();
};

export default WorkStudy;
