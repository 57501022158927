// DOM-specific JS should be done here
// see /boilerplate/src/js/browser.js for the import pattern

import TypeIt from 'typeit';

export const AnimatedType = (el) => {
  const ui = {
    el,
  };

  // console.log(typeit);

  const startTyping = () => {
    

new TypeIt(".animated-type", {
  speed: 70,
  startDelay: 200,
  afterComplete: function(){
    ui.el.classList.add('animated-type--completed');
  },
})
  .pause(500)
  .delete(6)
  .type("healtcare")
  .pause(400)
  .move(-4, { delay: 100 })
  .type("h", { delay: 400 })
  .pause(1000)
  .move(4, { delay: 100 })
  .delete(10)
  .type("government")
  .pause(1000)
  .delete(10)
  .type("nonprofit")
  .pause(1000)
  .delete(10)
  .pause(1000)
  .delete(10)
  .type("educational")
  .pause(1200)
  .delete(11)
  .type("impactful")
  .pause(1000)
  .delete(9)
  .pause(1500)
  .type("brilliant")
  .pause(500)
  .go();
  };

  const init = () => {
    startTyping();
  };

  init();
};

export default AnimatedType;
