import select from 'dom-select';

const initModule = (method, selector) => {
  select.all(selector, document)
    .forEach((el) => {
      method(el);
    });
};

export default initModule;
