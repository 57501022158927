import select from 'dom-select';

export const MenuToggle = (el) => {
  const ui = {
    el,
    nav: select('.js-navigation', document),
    body: select('body', document),
  };

  // console.log(ui);

  const handleToggle = () => {
    ui.el.classList.toggle('is-active');
    ui.body.classList.toggle('nav-active');

    try {
      ui.nav.classList.toggle('is-active');
      ui.body.classList.toggle('nav-open');
    } catch {
      console.log('global-header not found');
    }
  };

  const addEvents = () => {
    ui.el.addEventListener('click', () => {
      handleToggle();
    });
  }

  const init = () => {
    addEvents();
  };

  init();
};

export default MenuToggle;
